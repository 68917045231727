import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCache } from './request.cache.service';

/**
 * Intercepts every http request and cache the response
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const skip = req.headers.get('NoCache') === 'true';
    if (req.headers.has('NoCache')) {
      const newHeaders = req.headers.delete('NoCache');
      req = req.clone({ headers: newHeaders });
    }
    if (skip) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);
    if (cachedResponse && req.method === 'GET') {
      return of(cachedResponse);
    } else {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse && req.method === 'GET' && event.status < 400) {
            this.cache.put(req.clone(), event.clone());
          }
        })
      );
    }
  }
}
