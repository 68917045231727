import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CACHE_MAX_AGE } from './cache.token';

/**
 * The http cache
 */
@Injectable({ providedIn: 'root' })
export class RequestCache {
  cache = new Map(); // Need this to not be private for testing
  private invalidateListeners = new Map<string, () => void>();

  constructor(@Inject(CACHE_MAX_AGE) private maxAge: number) {}

  onCacheIvalidated(url: string, callback: () => void) {
    this.invalidateListeners.set(url, callback);
  }

  private fireCacheInvalidated(urls: string[]) {
    Array.from(this.invalidateListeners.keys())
      .reduce((acc, key) => {
        if (urls.includes(key)) acc.push(key);
        return acc;
      }, [] as string[])
      .forEach(key => {
        const callback = this.invalidateListeners.get(key);
        if (callback) callback();
      });
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached || cached.lastRead < Date.now() - this.maxAge) {
      this.invalidate(url);
      return undefined;
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);
  }

  /**
   * Delete url's matching the given string, or if no string given - clear entire cache
   */
  invalidate(url?: string) {
    if (url != null) {
      // Url provided. Only remove urls matching given from cache
      Array.from(this.cache.keys()).forEach(k => {
        if (k.indexOf(url) > -1) {
          this.cache.delete(k);
        }
      });
      this.fireCacheInvalidated([url]);
    } else {
      // No url provided. Clear entire cache
      this.fireCacheInvalidated(Array.from(this.cache.keys()));
      this.cache.clear();
    }
  }
}
