import { MD5 } from 'object-hash';
import { objToString } from './stringUtils';
// import { objToString } from '.';

// function sortDictionary(obj: any): any {
//   if (obj == null || obj == undefined) {
//     return obj;
//   }
//   if (typeof obj != 'object') {
//     // it is a primitive: number/string (in an array)
//     return obj;
//   }
//   return Object.keys(obj)
//     .sort()
//     .reduce((acc: any, key: string) => {
//       if (Array.isArray(obj[key])) {
//         acc[key] = obj[key].map(sortDictionary);
//       } else if (typeof obj[key] === 'object') {
//         acc[key] = sortDictionary(obj[key]);
//       } else {
//         acc[key] = obj[key];
//       }
//       return acc;
//     }, {});
// }

export function objHash(obj: any) {
  // let jsonstringNoWhitespace: string = objToString(obj).replace(/\s+/g, '');
  // const hash = XXHash64.hash(0xcafebabe, jsonstringNoWhitespace);
  // return hash.toString(16).toUpperCase();
  return MD5(obj);
}

/**
 * Analyze two objects for equality. Order of object keys is irrelevant.
 */
export function compareObjects(a: any, b: any): boolean {
  try {
    return objHash(a) === objHash(b);
  } catch (ex) {
    // Probably some html reference in one of the objects. Stringify it first
    return objHash(objToString(a)) === objHash(objToString(b));
  }
}
