function findScrollContainer(elm: Element): Element {
  if (['auto', 'hidden'].includes(getComputedStyle(elm).overflow)) return elm;
  if (elm.parentElement != null) return findScrollContainer(elm.parentElement);
  return document.body; // Fallback to root element if no scroll-container found
}

function getBounds(elm: Element, container: Element) {
  const bounds = elm.getBoundingClientRect();
  const pBounds = container.getBoundingClientRect();
  return {
    top: bounds.top - pBounds.top + container.scrollTop,
    left: bounds.left - pBounds.left + container.scrollLeft,
    width: bounds.width,
    height: bounds.height,
  };
}

export function isElementInView(elm: Element, container = findScrollContainer(elm)) {
  const containerBounds = container.getBoundingClientRect();
  const elmBounds = elm.getBoundingClientRect();
  return (
    elmBounds.top >= containerBounds.top &&
    elmBounds.bottom <= containerBounds.bottom &&
    elmBounds.left >= containerBounds.left &&
    elmBounds.right <= containerBounds.right
  );
}

export type ScrollPosition = 'top' | 'left' | 'right' | 'bottom' | 'center';
type ScrollIntoViewOptions = {
  elm: Element;
  containerSearchFrom?: Element;
  container?: Element;
  scrollPadding?: number;
  position?: ScrollPosition;
};
export function scrollIntoView(options: ScrollIntoViewOptions | Element) {
  if (!('elm' in options)) options = { elm: options };
  const searchContainer = options.containerSearchFrom || options.elm;
  const opts = Object.assign(
    {
      containerSearchFrom: options.elm,
      container: findScrollContainer(searchContainer),
      scrollPadding: 0,
      position: 'center',
    },
    options,
  ) as Required<ScrollIntoViewOptions>;
  const { left, top, width, height } = getBounds(opts.elm, opts.container);
  const containerWidth = opts.container.clientWidth;
  const containerHeight = opts.container.clientHeight;

  const scrollToOptions: Required<ScrollToOptions> = { behavior: 'smooth', top: 0, left: 0 };

  switch (opts.position) {
    case 'top':
      scrollToOptions.top = top;
      break;
    case 'right':
      scrollToOptions.top = top;
      scrollToOptions.left = left - containerWidth;
      break;
    case 'bottom':
      scrollToOptions.top = top - containerHeight;
      scrollToOptions.left = left;
      break;
    case 'center':
      const heightOffset = containerHeight - height;
      const widthOffset = containerWidth - width;
      scrollToOptions.top = top - heightOffset / 2;
      scrollToOptions.left = left - widthOffset / 2;
      break;
    default:
      // no position specified, default to scrolling to top left of element
      scrollToOptions.top = top;
      scrollToOptions.left = left;
  }
  scrollToOptions.top += opts.scrollPadding;
  scrollToOptions.left += opts.scrollPadding;
  requestAnimationFrame(() => opts.container.scrollTo(scrollToOptions));
}
