import { getEnv } from './getEnv';

const isDev = location.hostname === 'localhost' || getEnv('env') === 'dev';

/**
 * @deprecated use loggerService instead.
 */
export function logger(msg: string, from = 'App', isImportant = false, bgColor = 'blue', fgColor = 'white') {
  const style = `background-color: ${bgColor}; color: ${fgColor}; padding: 2px 8px; border-radius: 4px;`;
  if (isDev || isImportant) {
    console.log(`%c${from}:`, style, msg);
  }
}
