export class Browser {
  /**
   * Returns true if the current browser is a mobile device.
   */
  static isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  /**
   * Returns true if the current browser is a mobile device.
   */
  static isIOS(): boolean {
    return (
      /iPhone|iPad|iPod/i.test(navigator.userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  static getBrowser() {
    const ua = navigator.userAgent;
    const tem = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    const M = ua.match(/(edge(?=\/))\/?\s*(\d+)/i) || [];
    const browser = M[1] || tem[1] || (this.isIOS() ? 'Safari' : ua);
    const version = M[2] || tem[2] || (this.isMobile() ? 'Mobile' : '');
    return { browser, version, isMobile: this.isMobile() };
  }
}
