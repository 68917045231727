const storage = new Map<string, string>();

const storageMock = {
  getItem: (key: string): string | null => {
    return storage.has(key) ? (storage.get(key) as string) : null;
  },
  removeItem: (key: string) => {
    return storage.delete(key);
  },
  setItem: (key: string, value: string) => {
    return storage.set(key, value);
  },
  length: storage.size,
  clear: () => storage.clear(),
  key: idx => [...storage.keys()][idx],
} as Storage;

export function getLocalStorage(): Storage {
  try {
    return window.self.localStorage;
  } catch (ex) {
    // Could not get localStorage. Use memory storage instead.
    return storageMock;
  }
}
