import { Params } from '@angular/router';

export function trimObject(obj = {}, manipulator?: (key: string, value: any) => any) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    value = value != null && manipulator != null ? manipulator(key, value) : value;
    if (value != null) {
      return Object.assign(acc, { [key]: value });
    }
    return acc;
  }, {});
}

export function trimParam(query: string) {
  if (!query) return {} as Params;
  return trimObject(
    query.split('&').reduce((acc, p) => {
      const [key, value] = p.split('=');
      return Object.assign(acc, { [key]: value });
    }, {})
  ) as Params;
}
