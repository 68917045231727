import { darken, hue, lighten, saturate } from './colorUtils';

// --------------------------------------------------------------------
// Chart colors

// --------------------------------------------------------------------
const blue = '#114D70';
const orange = '#8A5414';
const red = '#6D1611';
const green = '#3E7126';
const purple = '#7F3F6C';

export const primaryPalette = [
  blue,
  lighten(blue, 10),
  lighten(blue, 20),
  lighten(blue, 30),
  lighten(blue, 40),
  saturate(lighten(blue, 60), 40),
];
export const accentPalette = [
  darken(orange, 20),
  darken(orange, 10),
  orange,
  hue(lighten(orange, 19), -20),
  hue(lighten(orange, 29), -30),
];
export const warningPalette = [
  red,
  hue(lighten(red, 17), 5),
  hue(lighten(red, 20), 10),
  hue(lighten(red, 17), 30),
  hue(lighten(red, 7), 50),
];
// prettier-ignore
export const vibrantPalette = [
  '#FF5733', '#FFC300', '#DAF7A6', '#900C3F', // Vibrant colors
  '#3177a5', '#2E86C1', '#AED6F1', '#A569BD', // Cool colors
  '#581845', '#C70039', '#CAE995', '#266982', // Warm colors
  '#196F3D', '#F1C40F', '#F39C12', '#E74C3C', // Green and Yellow shades
  '#CB4335', '#6C3483', '#1F618D', '#17202A'  // Red and Blue shades
];

// prettier-ignore
export const newPalette = [
  '#3283FF', '#FDAE18', '#B10068', '#1EFFCF',
  '#92AD1B', '#2FD9FF', '#F7222E', '#315A9B',
  '#18FF33', '#DEA0FD', '#1C8356', '#FAA19F',
  '#FF00FC', '#C5441C', '#AB0BFF', '#85660C',
  '#B10DA1', '#FCE625', '#1DBF4E', '#FC0087',
  '#F7E1A1', '#C177A5', '#782AB7', '#AAF401',
  '#FE1CBF', '#BDCEFF', '#832E1D', '#B5EFB5',
  '#7ED7D1', '#1C7E92', '#D95EF7', '#683A7A',
  '#66B1FF', '#3A00FB', '#5A5156', '#E4E1E3',
];

export const greenPalette = [green, lighten(green, 10), darken(green, 40), primaryPalette[0]];
export const greyPalette = primaryPalette.map((p) => saturate(lighten(p, 10), 0));

export const graphPalette = [
  primaryPalette[0],
  greyPalette[4],
  accentPalette[0],
  darken(warningPalette[3], 20),
  purple,

  primaryPalette[2],
  greyPalette[0],
  accentPalette[2],
  warningPalette[3],
  lighten(purple, 20),

  primaryPalette[4],
  greyPalette[2],
  accentPalette[4],
  warningPalette[4],
  lighten(purple, 40),
];

// #8509 - Color palette for graphs in both light mode and dark mode
export const bluePalette = ['#1d4d6f', '#3a8ac9', '#4095d9', '#65b8e7', '#7ec5ee', '#a1deff'];
export const grayPalette = ['#5a5a5a', '#8c8c8e', '#8c8d8d', '#bfc0c1', '#e7e7e7'];
export const brownPalette = [
  '#311e06',
  '#603c0d',
  '#895513',
  '#8b7f17',
  '#9f8e19',
  '#ab8946',
  '#b8721e',
  '#bb9e65',
  '#c67c1f',
  '#cab58b',
];
export const purplePalette = ['#7f3f6c', '#b76ca1', '#bf73a9', '#d9b1cd', '#e5554c', '#f05a51'];
export const graphPaletteLight = [
  bluePalette[0],
  brownPalette[0],
  brownPalette[1],
  purplePalette[0],
  bluePalette[1],
  grayPalette[0],
  brownPalette[2],
  brownPalette[6],
  purplePalette[1],
  bluePalette[3],
  grayPalette[2],
  purplePalette[4],
  brownPalette[3],
  purplePalette[3],
];
export const graphPaletteDark = [
  bluePalette[2],
  brownPalette[5],
  brownPalette[8],
  purplePalette[0],
  bluePalette[4],
  grayPalette[1],
  brownPalette[7],
  brownPalette[9],
  purplePalette[2],
  bluePalette[5],
  grayPalette[4],
  purplePalette[5],
  brownPalette[5],
  purplePalette[3],
];
