import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CACHE_MAX_AGE } from './cache.token';
import { CacheInterceptor } from './cache.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: CACHE_MAX_AGE, useValue: 1000 * 60 * 5 },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
})
export class CacheModule {}
