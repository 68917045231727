/**
 * Debounce function decorator
 *
 * @param delay
 * @param shouldInvokeImmediately
 * @returns the function debounced
 */
export function Debouncer(delay = 200, immediate = false): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = debounce(originalMethod, delay, immediate);
    return descriptor;
  };
}

/**
 *
 * @param func the function to debounce
 * @param delay milliseconds after last invocation before execution happens
 * @param immediate if the function should be invoked on first call
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, delay = 200, immediate = false) {
  let timerId: ReturnType<typeof setTimeout> | undefined = undefined;
  let lastCallTime: number | null = null;

  return function (...args: any): Promise<any> {
    const self = this;

    return new Promise((resolve) => {
      const now = Date.now();
      const callNow = immediate && !timerId;

      if (callNow) {
        lastCallTime = now;
        const result = func.apply(self, args);
        result instanceof Promise ? result.then((res) => resolve(res)) : resolve(result);
        timerId = setTimeout(() => {
          timerId = undefined;
        }, delay);
      } else {
        const elapsedTime = now - (lastCallTime || 0);
        if (lastCallTime && elapsedTime < delay) {
          clearTimeout(timerId);
        }
        lastCallTime = now;
        timerId = setTimeout(() => {
          timerId = undefined;
          if (!immediate) {
            const result = func.apply(self, args);
            result instanceof Promise ? result.then((res) => resolve(res)) : resolve(result);
          }
        }, delay);
      }
    });
  };
}
