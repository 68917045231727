export function waitForElement(selector: string, relativeToElement: Element | Document = document): Promise<Element> {
  let timePassed = 0;
  let isResolved = false;
  let interval: any;
  return new Promise((resolve, reject) => {
    interval = setInterval(() => {
      if (isResolved) return;
      const el = relativeToElement.querySelector(selector);
      if (timePassed > 5000 && !el) {
        clearInterval(interval);
        reject('Element search timed out!');
      }
      if (el) {
        if (interval) clearInterval(interval);
        isResolved = true;
        resolve(el as Element);
      }
      timePassed += 100;
    }, 100);
  });
}
